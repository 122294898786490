import React from 'react';
import { ArrowUpRight } from 'phosphor-react';
import Link from 'adapter/next/link';
import { Box, Heading, Tag, cn } from '@superside/ui';
import { NextFutureImage } from '@konsus/image';
import { AiTag } from './AiTag';
import styles from './ServicesTiles.module.css';
import { type TileProps } from '.';

export const Tile: React.FC<TileProps> = (props) => {
  const { link, bgImage, isAiEnhancementAvailable, title, description, tags, asset, mobileAsset } =
    props;

  return (
    <Box className={cn('1280:px-2 1280:h-[494px] 1280:w-[983px] h-[394px] w-[296px]', styles.card)}>
      <Link href={link} className='group h-full'>
        <Box
          className={cn('1280:p-12 relative h-full rounded-lg bg-indigo-300 p-6', styles.wrapper)}
        >
          {bgImage ? (
            <NextFutureImage
              className='absolute left-0 top-0 !h-full w-full rounded-lg object-cover object-center'
              image={bgImage}
            />
          ) : null}

          {asset ? (
            <Box
              className={cn(
                '1280:right-[28px] 1280:top-0 1280:h-full 1280:w-[410px] <1280:left-[50%] <1280:-translate-x-2/4 absolute -top-[26px] z-[1] h-[186px] w-[246px] transition-all duration-200',
                styles.asset
              )}
            >
              <Box
                style={{
                  background:
                    'linear-gradient(0deg, rgba(15, 33, 51, 0.10) 0%, rgba(15, 33, 51, 0.10) 100%), rgba(250, 250, 250, 0.10)'
                }}
                className={cn(
                  '1280:flex absolute left-[50%] top-[50%] hidden h-[96px] w-[96px] -translate-x-2/4 -translate-y-2/4 rounded-[50%] backdrop-blur-[20px]',
                  styles.arrow
                )}
              >
                <ArrowUpRight
                  className='absolute left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4'
                  size={32}
                />
              </Box>
              <NextFutureImage
                requestWidth={886}
                className={cn(
                  '1280:!h-auto 1280:w-full',
                  styles.image,
                  mobileAsset && '<1280:hidden'
                )}
                image={asset}
              />

              {mobileAsset ? (
                <NextFutureImage
                  className={cn('1280:hidden h-full object-contain')}
                  image={mobileAsset}
                />
              ) : null}
            </Box>
          ) : null}

          <Box className='z-10 h-full'>
            <Box className='1280:justify-between h-full max-w-[542px] justify-end'>
              <Box className='1280:text-left 1280:items-start items-center justify-end gap-4 text-center'>
                {isAiEnhancementAvailable ? (
                  <Tag label={<AiTag />} uppercase={false} variant='glassy' size='sm' />
                ) : null}
                <Heading
                  level='h3'
                  className='1280:text-6xl 1280:leading-[72px] text-[32px] leading-10'
                >
                  {title}
                </Heading>
                {description ? <span className='max-w-[440px]'>{description}</span> : null}
              </Box>

              {tags ? (
                <Box className='1280:flex hidden flex-row flex-wrap gap-2'>
                  {tags.map((tag) => (
                    <Tag
                      key={tag}
                      label={tag}
                      uppercase={false}
                      variant='glassy'
                      glassy={{ background: 'fill', border: false }}
                      size='sm'
                    />
                  ))}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};
