import React, { useRef } from 'react';
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { CaretRight } from 'phosphor-react';
import { Box, cn } from '@superside/ui';
import { type SanityAsset } from '@konsus/image/src/types';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { PageSection } from '../../components/PageSection';
import Superscript from '../../components/Superscript';
import { Tile } from './Tile';
import { Rocks } from './Rocks';
import styles from './ServicesTiles.module.css';

export interface TileProps {
  _key: string;
  title: string;
  description: string;
  tags: string[];
  link: string;
  isAiEnhancementAvailable: boolean;
  asset: SanityAsset;
  mobileAsset?: SanityAsset;
  bgImage: SanityAsset;
}

export interface ServicesTilesProps {
  superscript?: string;
  headerRichText?: { content: Array<any> };
  tiles: TileProps[];
  autoplayDelay?: number;
  showRocks?: boolean;
}

export const ServicesTiles: React.FC<ServicesTilesProps> = ({
  superscript,
  headerRichText,
  tiles,
  autoplayDelay = 3000,
  showRocks = true,
  ...rest
}) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <PageSection unlimited {...rest}>
      <Box className='overflow-x-hidden'>
        {superscript || headerRichText ? (
          <Box className='max-w-[1036px] self-center px-8 text-center'>
            {superscript ? <Superscript content={superscript} /> : null}

            {headerRichText ? (
              <SanityBlockComponent blocks={headerRichText} renderNormalAsHeading='h3' />
            ) : null}
          </Box>
        ) : null}

        <Box className='relative'>
          {showRocks ? <Rocks /> : null}
          <Box
            className={cn(
              '1280:[-webkit-mask-image:-webkit-linear-gradient(0deg,transparent_0%,#000_10%,#000_90%,transparent_100%)]',
              styles.container
            )}
          >
            <Swiper
              ref={swiperRef}
              slidesPerView={'auto'}
              centeredSlides={true}
              loop={true}
              spaceBetween={16}
              autoplay={{
                delay: autoplayDelay,
                disableOnInteraction: true
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className='services-swiper'
              speed={700}
            >
              {tiles.map((item) => (
                <SwiperSlide key={item._key}>
                  <Tile {...item} />
                </SwiperSlide>
              ))}
            </Swiper>

            <Box className='mx-auto flex-row items-center justify-center gap-2'>
              <Box
                className='hover:bg-bor-light/[0.15] h-12 w-12 cursor-pointer items-center justify-center rounded'
                onClick={() => {
                  swiperRef?.current?.swiper.slidePrev();
                }}
              >
                <CaretRight size={20} weight='bold' className='rotate-180' />
              </Box>
              <Box
                className='hover:bg-bor-light/[0.15] h-12 w-12 cursor-pointer items-center justify-center rounded'
                onClick={() => {
                  swiperRef?.current?.swiper.slideNext();
                }}
              >
                <CaretRight size={20} weight='bold' />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageSection>
  );
};

export default ServicesTiles;
