import React from 'react';
import { Box, Text } from '@superside/ui';

export const AiTag = () => {
  return (
    <Box className='flex-row items-center gap-2'>
      <Box>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='17'
          height='16'
          viewBox='0 0 17 16'
          fill='none'
        >
          <path
            d='M7.29684 9.54479L10.25 8.75L7.29684 7.95521C6.99059 7.87135 6.75362 7.63438 6.66977 7.32813L5.875 4.375L5.08019 7.32813C4.99633 7.63438 4.75937 7.87135 4.45312 7.95521L1.5 8.75L4.45312 9.54479C4.75937 9.62865 4.99633 9.86563 5.08019 10.1719L5.875 13.125L6.66977 10.1719C6.75362 9.86563 6.99059 9.62865 7.29684 9.54479Z'
            fill='currentColor'
          />
          <path
            d='M13.7281 3.97688L15.5 3.5L13.7281 3.02312C13.5444 2.97281 13.4022 2.83063 13.3519 2.64688L12.875 0.875L12.3981 2.64688C12.3478 2.83063 12.2056 2.97281 12.0219 3.02312L10.25 3.5L12.0219 3.97688C12.2056 4.02719 12.3478 4.16938 12.3981 4.35313L12.875 6.125L13.3519 4.35313C13.4022 4.16938 13.5444 4.02719 13.7281 3.97688Z'
            fill='currentColor'
          />
        </svg>
      </Box>
      <Text size='xs'>AI-Enhancement Available</Text>
    </Box>
  );
};
