import React from 'react';
import { Box, cn } from '@superside/ui';
import styles from './ServicesTiles.module.css';

export const Rocks = () => {
  return (
    <Box className={cn('hidden', styles.rocksContainer)}>
      <Box className='absolute bottom-[37px] left-[157px] z-10 h-[156px] w-[280px]'>
        <img
          className={cn(styles.leftFirstRock, 'absolute left-[34px] top-[63px] h-auto w-[57px]')}
          src='/images/rocks/rock-1.png'
        />
        <img
          className={cn(styles.leftSlowRock, 'absolute left-[89px] top-[52px] h-auto w-[57px]')}
          src='/images/rocks/rock-2.png'
        />
        <img
          className={cn(styles.leftRevertRock, 'absolute left-[114px] top-[73px] h-auto w-[67px]')}
          src='/images/rocks/rock-3.png'
        />
        <img
          className={cn(styles.leftSlowRock, 'absolute left-[172px] top-[24px] h-auto w-[57px]')}
          src='/images/rocks/rock-4.png'
        />
      </Box>

      <Box className='absolute right-[-134px] top-[-145px] z-10 h-[320px] w-[520px]'>
        <img
          className={cn(styles.rocks, 'absolute left-[34px] top-[107px] h-auto w-[57px]')}
          src='/images/rocks/rock-1.png'
        />
        <img
          className={cn(styles.rocks, 'absolute left-[89px] top-24 h-auto w-[57px]')}
          src='/images/rocks/rock-2.png'
        />
        <img
          className={cn(styles.slowRock, 'absolute left-[114px] top-[118px] h-auto w-[67px]')}
          src='/images/rocks/rock-3.png'
        />
        <img
          className={cn(styles.rocks, 'absolute left-[172px] top-[68px] h-auto w-[57px]')}
          src='/images/rocks/rock-4.png'
        />
        <img
          className={cn(styles.rocks, 'absolute left-[89px] top-1 h-auto w-[428px]')}
          src='/images/rocks/rock-5.png'
        />
      </Box>
    </Box>
  );
};
